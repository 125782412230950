import { AuthenticationAwsService } from '@service/authentication.service';
import { Environment } from './environment.type';

export const environment: Environment = {
  atruvia: null,
  aws: {
    cognitoUserPoolId: 'eu-central-1_Nkz9IRfJN',
    cognitoClientId: '2fhq6cu5tjbu3moh5me4tvn5kk',
  },
  service: {
    authentication: AuthenticationAwsService,
  },
  caUrl: 'ca/api/v1',
  gshUrl: 'gsh/api/v1',
  local: false,
};

export function getAuthenticationService(): AuthenticationAwsService {
  return AuthenticationAwsService.prototype;
}
